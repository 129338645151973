@import 'styles/shared/_shared.scss';

.register-in-header {
  width: 21.5vw;
  margin-left: 4.8vw;
  text-align: center;

  .register-button {
    width: 100%;
    height: 8vw;
    border: none;
    margin: 0;
    padding: 0;
    background: transparent $gradation-color 0% 0% no-repeat padding-box;
    border-radius: 4vw;
    > p {
      font-size: 2.66vw;
      padding: 0;
      margin: 0;
      font-weight: bold;
      color: $color-true-white;
    }
  }
}

@media (min-width: 751px * $target-scales) {
  .register-in-header {
    width: 132px * $target-scales;
    margin-left: 36px * $target-scales;

    > .register-button {
      height: 60px * $target-scales;
      border-radius: 30px * $target-scales;

      > p {
        font-size: 20px * $target-scales;
      }
    }
  }
}
