@import 'styles/shared/_shared.scss';

.stepper {
  display: flex;
  width: $px640;
  margin: 0 auto;
  padding: $px22 0 0;
  justify-content: space-between;

  .step {
    width: 100%;
    position: relative;

    &.current {
      font-weight: bold;

      > .label {
      color: #32363D;
      }
      > .lines .point {
        background-color: #FF4282;
      }
    }

    &.done {
      color: $light-gray;
    }

    $point-width: 2.66vw;
    > .label {
      font-size: $point-width;
      text-align: center;
      width: 100%;
      margin: 0 auto $point-width;
      color: #A0A4AA;
    }

    > .lines {
      position: relative;
      > .point {
        background-color: #DFE0E2;
        width: $point-width;
        height: $point-width;
        border-radius: $point-width / 2;
        margin: 0 auto;
        position: absolute;
        left: calc(50% - $point-width / 2);
      }

      > .left-box, .right-box {
        position: absolute;
        width: 50%;
        height: 0;
        top: $point-width / 2 - $px4 / 2;
        border-bottom: $px4 solid #DFE0E2;

        &.hidden-box {
          display: none;
        }
      }
      > .left-box {
        left: 0;
      }
      > .right-box {
        right: 0;
      }
    }
  }
}

@media (min-width: 751px * $target-scales) {
  $point-width-fix: 20px * $target-scales;
  .stepper {
    width: 640px * $target-scales;
    padding: 22px * $target-scales 0 0;
      .step {
      width: 160px * $target-scales;

      > .label {
        font-size: 20px * $target-scales;
        margin: 0 auto 20px * $target-scales;
      }

      > .lines {
        position: relative;
        > .point {
          background-color: #DFE0E2;
          width: $point-width-fix;
          height: $point-width-fix;
          border-radius: $point-width-fix / 2;
          margin: 0 auto;
          position: absolute;
          left: calc(50% - $point-width-fix / 2);
        }

        > .left-box, .right-box {
          top: $point-width-fix / 2 - 4px / 2;
          border-bottom: 4px * $target-scales solid #DFE0E2;
        }
      }
    }
  }
}
