@import 'styles/shared/_shared.scss';

.page-header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-top: $px86;
  margin-bottom: 1em;

  > .back-button {
    height: 1.6em;
  }

  &.page-header-main {
    font-weight: bold;
    font-size: $px48;
    color: #32363D;
  }

  &.page-header-sub {
    font-weight: 600;
    font-size: 1.2em;
  }
}

@media (min-width: 751px * $target-scales) {
  .page-header {
    margin-top: 86px * $target-scales;

    &.page-header-main {
      font-size: 48px * $target-scales;
    }
  }
}
