/* Base */
$color-base: #fff;
$light-white: #f5f6f8;
$true-white: #fff;
$true-black: #000000;
$sub-black: #070707;
$dark-black: #333333;
$lighter-black: #B5B5B5;
$default-gray: #525252;
$sub-gray: #E0DEDE;
$light-gray: #AEAEAE;
$lighter-gray: #C4C4C4;
$lighter-gray2: #ECECEC;
$lightest-gray: #F1F1F1;
$lightest-gray2: #D9D9D9;
$light-gray-alpha70: rgba(163, 172, 192, 0.7);
$overlay-bgcolor: $sub-gray;
$overlay-bgcolor-darker: rgba(0, 0, 0, 0.7);
$overlay-bgcolor-lighter: rgba(0, 0, 0, 0.0);
$dark-gray: #8b93a6;
$darker-gray: #6B6A6A;
$darker-gray2: #7B7B7B;
$light-blue: #0e88e0;
$sky-blue: #0075E0;
$light-blue-alpha70: rgba(14, 136, 224, 0.7);
$light-black: #30394c;
$default-red: #E73434;
$default-red-transparent: rgba(231, 52, 52, 0.7);
$light-red: #D62077;
$light-pink: #FFDDDD;
$gradation: linear-gradient(119.96deg, #0ea1e0 0%, #af7feb 96.82%), #0e88e0;

/* default colors */
$color-content-text: $dark-black;
$color-content-text-reverse: $color-base;
$color-content-bg: $light-white;
$color-base-bg: $true-white;

/* main color */
$color-main: $default-gray;
$color-main-emphasis: $true-black;
$color-main-sub: $sub-gray;
$color-sub: $light-gray;
$color-main-reverse: $color-base;
$color-main-sub-reverse: $sub-black;
$color-sub-reverse: $true-white;
$color-main-hover: $light-blue-alpha70;
$color-sub-hover: $light-gray-alpha70;

$color-warning: $default-red;
$color-warning-hover: $default-red-transparent;

$color-disabled: $dark-gray;
$color-disabled-text: $light-gray;

/* text */
$color-placeholder: $light-gray;
$color-link: $sky-blue;

/* coomponent UI */
$color-component-ui-front: $true-black;
$color-component-ui-contrast: $true-white;


/******************************/
/*       color settings       */
/******************************/

$color-true-white: #fff;
$color-white: #FBFBFB;
$base-background-color: $color-white;
$color-gray: #707070;
$color-light-gray: #F4F4F4;
$header-border-color: $color-light-gray;
$color-gray-lighter: #c4c4c4;
$header-menu-open-bgcolor: $color-gray-lighter;
$color-light-green: #cceecc;
$base-overall-background-color: $color-light-green;
$gradation-color: linear-gradient(90deg, #FF4183 0%, #FF9300 100%);
$gradation-color-180: linear-gradient(180deg, #FF4183 0%, #FF9300 100%);
$gradation-background: transparent $gradation-color 0% 0% no-repeat padding-box;
$gradation-background-180: transparent $gradation-color-180 0% 0% no-repeat padding-box;
$color-black: #000;
$color-text: $color-black;
$color-smile-gray: #676C74;
$color-footer: $color-smile-gray;
$color-form-field-name: $color-smile-gray;
$color-light-gray: #F4F3F6;
$color-form-field-bg: $color-light-gray;
$color-red-pink: #FF4282;
$color-warning: $color-red-pink;
$gradation-color2: linear-gradient(#FFF0F1, #FFEDDD);
$gradation-header-bg: $gradation-color2;
