@import 'styles/shared/_shared.scss';


.register-steps {
  display: none;
  &.current {
    display: block;
  }

  padding: 0 20px;

  > .input-area {
    margin: 1em 0 2em;

    > .attention-required {
      margin: $px50 0 $px52;
      font-size: $px24;

      > span {
        color: #FF4282;
      }
    }

    .name-fields {
      margin-top: $px32;

      > .field-name {
        font-size: $px24;
        color: $color-form-field-name;
        font-weight: bold;

        > span.warning {
          // color: $color-warning;
          font-weight: normal;
          font-size: $px20;
        }

        > span.required {
          color: #FF4282;
        }
      }

      > .name-set {
        display: flex;
        justify-content: space-between;

        > .text-field-area {
          margin-top: 0;
          width: 49%;
        }
      }

      .validate {
        width: 48%;

        .text-field {
          margin-bottom: 0;
        }
      }
    }
  }

  .terms {
    margin-top: 10.13vw;
    color: #676C74;
    font-size: 2.66vw;
    line-height: 4.26vw;
    text-align: center;

    > .link-button {
      display: inline;
    }
  }

  > #inner-terms, #inner-privacy-policy {
    > .overlay-area > .overlay-inner {
      display: block;
      height: 80vh;
      overflow: hidden;
      overflow-y: scroll;
    }

  .bottom-close-button {
      text-align: center;
      display: flex;
      justify-content: center;
      margin: $px20 auto;

      > button {
        font-size: $px20;
      }
    }
  }

  .register-button {
    width: 100%;
  }
}

@media (min-width: 751px * $target-scales) {
  .register-steps {

    .terms {
      margin-top: 76px * $target-scales;
      font-size: 20px * $target-scales;
      line-height: 32px * $target-scales;
    }

    > #inner-terms, #inner-privacy-policy {

      > .overlay-area > .overlay-inner {
        display: block;
        height: 80vh;
        overflow: hidden;
        overflow-y: scroll;
      }

      .bottom-close-button {
        text-align: center;
        display: flex;
        justify-content: center;
        margin: 20px * $target-scales auto;

        > button {
          font-size: 20px * $target-scales;
        }
      }
    }

    .input-area {
      > .attention-required {
        margin: 50px * $target-scales 0 52px * $target-scales;
        font-size: 24px * $target-scales;

        > span {
          color: #FF4282;
        }
      }

      .name-fields {
        margin-top: 32px * $target-scales;
        > .field-name {
          font-size: 24px * $target-scales;

          > span.warning {
            font-size: 20px * $target-scales;
            font-weight: normal;
          }

          > span.required {
            color: #FF4282;
          }
        }

        > .name-set {
          > .text-field-area {
            margin-top: 0;
          }
        }
      }
    }
  }
}
