@import 'styles/shared/_shared.scss';


#admin-header {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background-color: $color-true-white;
  height: $header-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5.33vw;
  box-sizing: border-box;
  z-index: 10000;
  border-bottom: 2px solid $header-border-color;

  .left-area {
    width: 100%;
  }

  .middle-area {
    display: flex;
    align-items: center;
    margin-right: 4.8vw;
  }

  .right-area {

  }

  &.isOpen {
    background-color: $header-menu-open-bgcolor;
    > .left-area {
      visibility: hidden;
    }
  }
}

@media (min-width: 751px * $target-scales) {
  #admin-header {
    width: $content-width * $target-scales;
    height: $header-height-max * $target-scales;
    padding: 0 40px * $target-scales;

    .middle-area {
      margin-right: 36px * $target-scales;
    }
  }
}
