@import 'styles/shared/_shared.scss';
@import 'styles/shared/_overlay.scss';


.invitation-code-input-overlay {
  .text-input-area {
    box-sizing: border-box;

    width: 80%;
    min-width: 10em;
    margin: 0 auto;
    padding: 2em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    .header-title {
      font-weight: bold;
      text-align: center;
    }

    .input-field {
      border: none;
      width: 90%;
      max-width: 17em;
      margin: 2em auto;
    }
  }
}
