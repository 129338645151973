@import 'styles/shared/_shared.scss';

.register-steps {
  #register-completed {
    margin-top: $px340;
    text-align: center;

    > h2 {
      font-size: $px48;
      font-weight: bold;
    }

    > h3 {
      font-size: $px32;
      margin: $px36 auto $px22;
      font-weight: bold;
    }

    > .message-area {
      font-size: $px24;
      line-height: 2em;
      margin-bottom: $px240;
    }

    > .login-button {
      margin: $px56 auto $px548;
    }
  }
}

@media (min-width: 751px * $target-scales) {
  .register-steps {
    #register-completed {
      margin-top: 340px * $target-scales;
      > h2 {
        font-size: 48px * $target-scales;
      }
      > h3 {
        font-size: 32px * $target-scales;
        margin: 36px * $target-scales auto 22px * $target-scales;
      }
        > .message-area {
        font-size: 24px * $target-scales;
      }
      > .login-button {
        margin: 56px * $target-scales auto 548px * $target-scales;
      }
    }
  }
}
