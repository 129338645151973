@import 'styles/shared/_shared.scss';

$menu-current: $dark-black;
$menu-parent: $light-gray;
$menu-child: $lighter-gray;
$text-color: $true-white;
$disabled-parent-color: $darker-gray;
$disabled-child-color: $dark-gray;
$disabled-text-parent-color: $light-gray;
$disabled-text-child-color: $lighter-gray;
$border-color: $true-white;

.menu-button {
  width: 100%;
  border-bottom: 1px solid $border-color;

  button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.7em 2.1em 0.65em 2.1em;
    margin: 0;
    font-size: 0.95rem;
    font-weight: bold;
    color: $text-color;
    border-radius: 3em;
    border: none;
    align-content: center;
    justify-content: center;
    box-sizing: border-box;
    line-height: 1.5em;
    cursor: pointer;
    img {
      margin: 0 13px 0 -0.9em;
    }

    &:hover {
      opacity: 0.8;
    }


    &.parent {
      font-size: 1.1rem;
      font-weight: bold;
      border-radius: 0;
      background-color: $menu-parent;
      margin: 0;
      justify-content: flex-start;
      line-height: 3rem;
      padding-left: 2.5rem;

      &.current {
        background-color: $menu-current;
      }

      &.disabled {
        background-color: $disabled-parent-color;
        color: $disabled-text-parent-color;
        cursor: default;
      }
    }

    &.child {
      font-size: 1.0rem;
      font-weight: normal;
      border-radius: 0;
      background-color: $menu-child;
      margin: 0;
      justify-content: flex-start;
      line-height: 3rem;
      padding-left: 4.5rem;

      &.current {
        background-color: $menu-current;
        opacity: 0.7;
      }

      &.disabled {
        background-color: $disabled-child-color;
        color: $disabled-text-child-color;
        cursor: default;
      }
    }
  }
}
