@import 'styles/shared/_shared.scss';

.overlay-content {
  position: relative;
  background: inherit;
  width: $px670;
  height: $px388;
  padding: 0;
  marigin: 0;
  border-radius: 0;

  iframe {
    position:absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
  }
}
.close-button {
  position: absolute;
  top: $px-82;
  right: 0;
  width: $px82;
  height: $px82;
}
#teaser {
  positon: relative;
  width: 100%;
  margin: 0 auto;
  text-align: left;

  > #keyvisual {
    width: 100%;
    text-align: center;
    background-image: url(../../images/lp/keyvisual.png);
    background-size: cover;
    padding-top: $px104;
    padding-bottom: $px140;
    margin-bottom: $px126;

    > .annnouce-box {
      display: none;
      background-color: $color-true-white;
      width: $px592;
      margin: $px50 auto 0;
      border-radius: $px40;
      padding: $px40 0;

      color: #FF5562;
      text-align: center;
      >.title {
        font-size: $px28;
        font-weight: bold;
        margin: 0 auto $px20;
      }

      >.text {
        font-size: $px24;
        line-height: $px36;
      }
    }

    > .title-logo {
      width: $px616;
      margin: 0 auto 0 $px70;
      > img {
        width: 100%;
      }
    }

    > .title-text {
      width: $px504;
      margin: $px31 auto;
      > img {
        width: 100%;
      }
    }

    > .register-button {
      margin: $px605 auto $px56;
      width: $px560;
    }

    > .devices {
      line-height: $px48;
      color: #31343B;
      > .title {
        font-size: $px24;
        font-weight: bold;
      }
      > .device {
        font-size: $px28;
        > span {
          color: #BDBFC4;
          margin: 0 0.3em;
        }
      }
    }
  }
  > #about {
    width: 100%;
    height: $px1478;
    text-align: center;
    background-image: url(../../images/lp/bg_about.png);
    background-size: cover;
    padding-top: $px328;
    color: $color-true-white;
    font-weight: bold;

    > .lead {
      font-size: $px48;
      line-height: $px80;
    }
    > .lead-sub {
      margin: $px56 auto $px80;
      font-size: $px32;
      line-height: $px64;
    }
    > .movie-area {
      width: $px670;
      margin: 0 auto;
    }
  }
  > #features {
    > .title {
      width: $px630;
      height: $px259;
      margin: $px164 auto $px89;
      background-image: url(../../images/lp/bg_features.png);
      background-size: contain;
      background-position: right 0;
      background-repeat: no-repeat;
      padding-top: $px64;

      > img {
        width: 67.619%;
      }

      > .subtitle {
        font-size: $px28;
        color: #FF5562;
        font-weight: 600;
        display: flex;
        > .line {
          width: $px40;
          margin-top: $px19;
          height: 2px;
          background: $gradation-color;
          border-radius: $px10;
        }
        > .text {
          margin-left: $px16;
        }
      }
    }

    > .point {
      margin-top: $px-76;
      position: relative;
      > .lead-area {
        margin: 0px auto $px132;
        > .point-img {
          width: $px200;
          > img {
            width: 100%;
          }
        }
        > .lead {
          margin: $px-30 $px60 0 0;
          background: $color-true-white;
          border-radius: 0 $px60 0 0;
          padding: $px80 0 0 $px60;
          > .title {
            font-size: $px48;
            line-height: $px80;
            font-weight: bold;
          }
          > .explain {
            margin-top: $px48;
            font-size: $px28;
            line-height: $px56;
            letter-spacing: -1px;
          }
        }
      }

      > .item {
        width: $px670;
        margin: 0 auto $px136;

        > .title {
          height: $px102;
          margin: 0 $px10;

          > img {
            height: 100%;
          }
        }

        > .subtitle {
          color: #A0A4AA;
          margin: 0 $px10 $px44;
          font-size: $px22;
          line-height: $px26;
        }

        > .image img {
          width: 100%;
        }

        > .explain {
          display: flex;
          margin-top: $px42;
          > .line {
            width: $px40;
            margin-top: $px28;
            height: $px4;
            background: $gradation-color;
            border-radius: $px10;

          }
          > .text {
            margin-left: $px16;
            font-size: $px32;
            font-weight: bold;
            line-height: $px52;
          }
        }

        > .steps {
          position: relative;
          margin: $px58 auto;
          > .hr-box {
            width: 4px;
            height: 80%;
            position: absolute;
            top: $px50;
            left: $px66;
            background-color: #FF6A42;
            z-index: 0;
          }
          > .step {
            position: relative;
            z-index: 1;
            background-color: #F4F3F6;
            border-radius: $px40;
            margin-bottom: $px24;
            // padding: 0 $px30 0 0;
            padding: 0 0 0 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            > .left-box {
              // width: $px136;
              width: $px174;
              > img {
                width: 100%;
              }
            }
            // &:nth-child(1), &:nth-child(2), &:nth-child(4) {
            //   padding-right: $px46;
            // }
            > .right {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;

              > .inner-left {
                margin-left: $px32;
                font-size: $px28;
                line-height: $px42;
                font-weight: bold;
              }

              > .inner-right {
                > img {
                  width: 100%;
                }
                &.step01 {
                  width: $px56;
                  margin-right: $px46;
                }
                &.step02 {
                  width: $px73;
                  margin-right: $px46;
                }
                &.step03 {
                  width: $px92;
                  margin-right: $px32;
                }
                &.step04 {
                  width: $px76;
                  margin-right: $px46;
                }
              }
            }
          }
        }

        > img {
          width: 100%;
        }
      }
    }
    > .visual {
      width: $px750;
      > img {
        width: 100%;
      }
    }
  }

  > #tieup {
    background-image: url(../../images/lp/bg_tie_up.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    padding: $px184 $px40;
    width: 100%;

    > .title {
      width: $px614;
      margin: 0 auto;

      > img {
        width: 100%;
      }
    }
    > .subtitle {
      color: $color-true-white;
      font-size: $px40;
      line-height: $px48;
      text-align: center;
      font-weight: bold;
    }

    > .image {
      margin-top: $px108;

      > img {
        width: 100%;
      }
    }

    > .explain {
      font-size: $px28;
      line-height: $px56;
      color: $color-true-white;
      margin: $px60 auto;
      letter-spacing: -1px;
    }

    > .etc {
      position: relative;

      > .etc-box-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #FF9300;
        mix-blend-mode: multiply;
        opacity: 0.35;
        border-radius: $px56;
      }

      > .etc-box {
        position: relative;
        color: $color-true-white;
        margin: $px64 auto;
        font-size: $px32;
        line-height: $px56;
        font-weight: bold;
        padding: $px60 $px32 $px56 $px40;
          > ul {
          > li {
            font-weight: normal;
          }
        }
      }

    }

    > .text {
      color: $color-true-white;
      font-size: $px36;
      font-weight: bold;
      line-height: $px48;
      text-align: center;
      margin: $px48 auto;
    }

    > .attention {
      color: $color-true-white;
      font-size: $px28;
      line-height: $px60;
      margin: $px64 auto;
    }
  }

  > #balloon {
    margin: $px207 auto;
    > img {
      width: 100%;
    }
  }
}

@media (min-width: 751px * $target-scales) {
  .overlay-content {
    background-color: aqua;
    background: inherit;
    width: 670px * $target-scales;
    height: 388px * $target-scales;
  }
  .close-button {
    top: 82px * $target-scales;
    right: 0;
    width: 82px * $target-scales;
    height: 82px * $target-scales;
  }
  #teaser {
    margin: 0 auto;

    > #keyvisual {
      padding-top: 104px * $target-scales;
      padding-bottom: 140px * $target-scales;
      margin-bottom: 126px * $target-scales;

      > .annnouce-box {
        display: none;
        background-color: $color-true-white;
        width: 592px * $target-scales;
        margin: 50px * $target-scales auto 0;
        border-radius: 40px * $target-scales;
        padding: 40px * $target-scales 0;

        color: #FF5562;
        text-align: center;
        >.title {
          font-size: 28px * $target-scales;
          font-weight: bold;
          margin: 0 auto 20px * $target-scales;
        }

        >.text {
          font-size: 24px * $target-scales;
          line-height: 36px * $target-scales;
        }
      }

      > .title-logo {
        width: 616px * $target-scales;
        margin: 0 auto 0 70px * $target-scales;
      }

      > .title-text {
        width: 504px * $target-scales;
        margin: 31px * $target-scales auto;
      }

      > .register-button {
        margin: 605px * $target-scales auto 56px * $target-scales;
        width: 560px * $target-scales;
      }

      > .devices {
        line-height: 48px * $target-scales;
        > .title {
          font-size: 24px * $target-scales;
        }
        > .device {
          font-size: 28px * $target-scales;
        }
      }
    }
    > #about {
      height: 1478px * $target-scales;
      padding-top: 328px * $target-scales;

      > .lead {
        font-size: 48px * $target-scales;
        line-height: 80px * $target-scales;
      }
      > .lead-sub {
        margin: 56px * $target-scales auto 80px * $target-scales;
        font-size: 32px * $target-scales;
        line-height: 64px * $target-scales;
      }
      > .movie-area {
        width: 670px * $target-scales;
      }
    }
    > #features {
      // margin: 164px auto;
      > .title {
        width: 630px * $target-scales;
        height: 259px * $target-scales;
        margin: 164px * $target-scales auto 89px * $target-scales;
        padding-top: 63.72px * $target-scales;

        > img {
          width: 67.619%;
        }

        > .subtitle {
          font-size: 28px * $target-scales;
          > .line {
            width: 40px * $target-scales;
            margin-top: 19px * $target-scales;
            height: 2px * $target-scales;
            border-radius: 10px * $target-scales;
          }
          > .text {
            margin-left: 16px * $target-scales;
          }
        }
      }

      > .point {
        margin-top: -76px * $target-scales;
        > .lead-area {
          margin: 0px auto 132px * $target-scales;
          > .point-img {
            width: 200px * $target-scales;
          }
          > .lead {
            margin: -30px * $target-scales 60px * $target-scales 0 0;
            border-radius: 0 60px * $target-scales 0 0;
            padding: 80px * $target-scales 0 0 60px * $target-scales;
            > .title {
              font-size: 48px * $target-scales;
              line-height: 80px * $target-scales;
            }
            > .explain {
              margin-top: 48px * $target-scales;
              font-size: 28px * $target-scales;
              line-height: 56px * $target-scales;
              letter-spacing: -1px * $target-scales;
            }
          }
        }

        > .item {
          width: 670px * $target-scales;
          margin: 0 auto 136px * $target-scales;

          > .title {
            height: 102px * $target-scales;
            margin: 0 10px * $target-scales;
          }

          > .subtitle {
            margin: 0 10px * $target-scales 44px * $target-scales;
            font-size: 22px * $target-scales;
            line-height: 26px * $target-scales;
          }

          > .explain {
            margin-top: 42px * $target-scales;
            > .line {
              width: 40px * $target-scales;
              margin-top: 28px * $target-scales;
              height: 4px * $target-scales;
              border-radius: 10px * $target-scales;

            }
            > .text {
              margin-left: 16px * $target-scales;
              font-size: 32px * $target-scales;
              line-height: 52px * $target-scales;
            }
          }

          > .steps {
            margin: 58px * $target-scales auto;
            > .hr-box {
              width: 4px * $target-scales;
              height: 80%;
              top: 50px * $target-scales;
              left: 66px * $target-scales;
            }
            > .step {
              border-radius: 40px * $target-scales;
              margin-bottom: 24px * $target-scales;
              padding: 0 0 0 0;
              > .left-box {
                width: 174px * $target-scales;
              }
              // &:nth-child(1), &:nth-child(2), &:nth-child(4) {
              //   padding-right: 46px;
              // }
              > .right {
                > .inner-left {
                  margin-left: 32px * $target-scales;
                  font-size: 28px * $target-scales;
                  line-height: 42px * $target-scales;
                }
                > .inner-right {
                  &.step01 {
                    width: 56px * $target-scales;
                    margin-right: 46px * $target-scales;
                  }
                  &.step02 {
                    width: 73px * $target-scales;
                    margin-right: 46px * $target-scales;
                  }
                  &.step03 {
                    width: 92px * $target-scales;
                    margin-right: 32px * $target-scales;
                  }
                  &.step04 {
                    width: 76px * $target-scales;
                    margin-right: 46px * $target-scales;
                  }
                }
              }
            }
          }
        }
      }
    > .visual {
      width: 750px * $target-scales;
    }
  }

    > #tieup {
      background-image: url(../../images/lp/bg_tie_up.png);
      background-repeat: no-repeat;
      padding: 184px * $target-scales 40px * $target-scales;

      > .title {
        width: 614px * $target-scales;
        margin: 0 auto;

        > img {
          width: 100%;
        }
      }
      > .subtitle {
        color: $color-true-white;
        font-size: 40px * $target-scales;
        line-height: 48px * $target-scales;
        text-align: center;
        font-weight: bold;
      }

      > .image {
        margin-top: 108px * $target-scales;
      }

      > .explain {
        font-size: 28px * $target-scales;
        line-height: 56px * $target-scales;
        color: $color-true-white;
        margin: 60px * $target-scales auto;
        letter-spacing: -1px;
      }

      > .etc {
        position: relative;

        > .etc-box-bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #FF9300;
          mix-blend-mode: multiply;
          opacity: 35%;
          border-radius: 56px * $target-scales;
        }

        > .etc-box {
          position: relative;
          color: $color-true-white;
          margin: 64px * $target-scales auto;
          font-size: 32px * $target-scales;
          line-height: 56px * $target-scales;
          font-weight: bold;
          padding: 60px * $target-scales 32px * $target-scales 56px * $target-scales 40px * $target-scales;
          > ul {
            > li {
              font-weight: normal;
            }
          }
        }

      }

      > .text {
        color: $color-true-white;
        font-size: 36px * $target-scales;
        font-weight: bold;
        line-height: 48px * $target-scales;
        text-align: center;
        margin: 48px * $target-scales auto;
      }

      > .attention {
        color: $color-true-white;
        font-size: 28px * $target-scales;
        line-height: 60px * $target-scales;
        margin: 64px * $target-scales auto;
      }
    }

    > #balloon {
      margin: 207px * $target-scales auto;
      width: 750px * $target-scales;
    }
  }
}
