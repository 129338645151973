@import 'styles/shared/_shared.scss';



.text-confirm {
  margin-top: $px28;
  border-bottom: 1px solid #F4F4F4;

  > .title {
    color: #676C74;
    font-size: $px24;
    font-weight: bold;
  }
  > .value {
    color: #32363D;
    font-size: $px28;
    font-weight: bold;
    margin-top: $px26;
    margin-bottom: $px48;
  }

}

@media (min-width: 751px * $target-scales) {
  .text-confirm {
    margin-top: 28px * $target-scales;
    > .title {
      font-size: 24px * $target-scales;
    }
    > .value {
      font-size: 28px * $target-scales;
      margin-top: 26px * $target-scales;
      margin-bottom: 48px * $target-scales;
    }
  }
}
