@import 'styles/shared/_shared.scss';


#admin-footer {
  width: 100%;
  padding: $px40 0 $px40;
  background-color: $color-true-white;

  #copyright {
    width: 100%;
    text-align: center;
    color: $color-footer;
    font-size: $px20;
  }
}

@media (min-width: 751px * $target-scales) {
  #admin-footer {
    width: $content-width * $target-scales;
    padding: 40px * $target-scales 0 200px * $target-scales;

    > #copyright {
      font-size: 20px * $target-scales;
    }
  }
}
