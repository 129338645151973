@import 'styles/shared/_shared.scss';

#teaser-layout {
  height: 100vh;
  margin: 0 auto;

  .teaser-layout-inner {
    width: 100%;
    position: relative;
    margin: 0 auto;

    .menu-area {
      display: none;
      position: absolute;
      &.isOpen {
        display: block;
        z-index: 1000;
        width: 100%;
      }
    }

    .menu-spacer-footer {
      height: 2em;
    }

    #content {
      margin: $header-height auto 0;
      width: 100%;
      position: relative;
      background-color: $color-true-white;
      overflow-x: hidden;
      // padding: 0 $px40;
    }

    .privacy-policy {
      text-align: center;
      background: $color-true-white;
      padding-top: $px120;
      font-size: $px20;
    }
  }
}

@media (min-width: 751px * $target-scales) {
  #teaser-layout .teaser-layout-inner {
    width: $content-width * $target-scales;
    position: relative;

    #content {
      margin: $header-height-max * $target-scales auto 0;
      width: 750px * $target-scales;
      // padding: 0 40px;
    }

    .privacy-policy {
      padding-top: 120px * $target-scales;
      font-size: 20px * $target-scales;
    }
  }
}
