@import 'styles/shared/_shared.scss';

.movie-play-button {
  width: 100%;
  position: relative;

  .base-layer {
    width: 100%;
  }
  .over-layer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    > .image-button {
    width: 100%;
      > .image-button {
        &:hover {
          // opacity: $button-hover-opacity;
          opacity: 0.5;
          background-color: #000;
          border-radius: $px48;
        }
        > img {
          width: 23.88%;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}

@media (min-width: 751px * $target-scales) {
  .movie-play-button {
    .base-layer {
    }
    .over-layer {
      > .image-button {
        > .image-button {
          &:hover {
            border-radius: 48px * $target-scales;
          }
        }
      }
    }
  }
}
