@import 'styles/shared/_shared.scss';

.back-button {

  &.small {
    width: 0.6rem;
    margin-right: 0.5em;
  }
  &.medium {
    width: 1.2rem;
    margin-right: 0.8em;
  }
  &.large {
    width: 3rem;
    margin-right: 1em;
  }
}
