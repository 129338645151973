@import 'styles/shared/_shared.scss';

.image-button {
  width: 100%;
  height: 100%;
  line-height: 0;

  button.image-button {
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    line-height: 0;
    width: 100%;
    background-color: transparent;

    img {
      width: 100%;
      height: 100%;
    }

    :hover {
      opacity: 0.8;
    }

    &.disabled {
      opacity: 0.7;
      pointer-events: none;
    }
  }
}
