@import 'styles/shared/destyle.css';
@import 'styles/shared/_shared.scss';


body {
}

@media (min-width: 751px * $target-scales) {
  #bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: url(images/bg.png);
    background-position: center;
    background-size: cover;
  }

  #bg-line {
    width: 790px * $target-scales;
    height: 100vh;
    background-color: #fff;
    margin: 0 auto;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
