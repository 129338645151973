@import 'styles/shared/_shared.scss';


.register-steps {
  display: none;
  &.current {
    display: block;
  }

  > .confirm-area {
    margin: 1em 0 2em;

    .name-fields {
      margin-top: $px32;

      > .field-name {
        font-size: $px24;
        color: $color-form-field-name;
        font-weight: bold;

        > span.warning {
          color: $color-warning;
          font-weight: normal;
          font-size: $px20;
        }
      }

      > .name-set {
        display: flex;
        justify-content: space-between;

        > .text-field-area {
          margin-top: 0;
          width: 49%;
        }
      }

      .validate {
        width: 48%;

        .text-field {
          margin-bottom: 0;
        }
      }
    }
  }

  .buttons {
    margin-top: $px64;
    display: flex;
    justify-content: space-between;
    > .text-button {
      width: 49%;
    }
  }
}

@media (min-width: 751px * $target-scales) {
  .register-steps {

    > .confirm-area {
      > .name-fields {
        margin-top: 32px * $target-scales;
        > .field-name {
          font-size: 24px * $target-scales;
        }

        > .name-set {
          > .text-field-area {
            margin-top: 0;
          }
        }
      }
    }

    > .buttons {
      margin-top: 64px * $target-scales;
    }

  }
}
