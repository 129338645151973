@import 'styles/shared/_shared.scss';

.custom-dialog {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
  background-color: rgba(48, 57, 76, 0.5);
}

.custom-dialog-inner {
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 0 $px144;
  width: $px700;
  text-align: center;
  background-color: $color-base;
  border-radius: $px40;
  transform: translateY(-50%) translateX(-50%);
  z-index: 999999;
  font-size: 1.1rem;

  .custom-dialog-title {
    margin: $px74 auto $px34;
    font-size: $px36;
    line-height: $px64;
    font-weight: bold;
  }
  .custom-dialog-text {
    font-size: $px24;
    color: $color-content-text;
    margin: 0.4em 0;
  }
  .custom-dialog-subtext {
    font-size: $px24;
    color: $color-sub;
    display: none;
  }

  .custom-dialog-buttons {
    display: flex;
    justify-content: center;

    .custom-dialog-button {
      width: $px412;
      margin: $px60 0 $px60;

      &.cunstom-dialog-cancel {
        width: 6em;
        margin-right: 0.5em;
      }

      &.cunstom-dialog-ok {
        width: 6em;
        margin-left: 0.5em;
      }

    }

  }
}

@media (min-width: 751px * $target-scales) {
  .custom-dialog-inner {
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 0 144px * $target-scales;
    width: 700px * $target-scales;
    text-align: center;
    background-color: $color-base;
    border-radius: 40px * $target-scales;
    transform: translateY(-50%) translateX(-50%);
    z-index: 999999;
    font-size: 1.1rem;

    .custom-dialog-title {
      margin: 74px * $target-scales auto 34px * $target-scales;
      font-size: 36px * $target-scales;
      line-height: 64px * $target-scales;
      font-weight: bold;
    }
    .custom-dialog-text {
      font-size: 24px * $target-scales;
      color: $color-content-text;
      margin: 0.4em 0;
    }
    .custom-dialog-subtext {
      font-size: 24px * $target-scales;
      color: $color-sub;
    }

    .custom-dialog-buttons {
      .custom-dialog-button {
        width: 412px * $target-scales;
        margin: 60px * $target-scales 0 60px * $target-scales;
      }
    }
  }
}
