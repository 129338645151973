@import 'styles/shared/_shared.scss';

.logo {
  &.header-admin {
    width: $px248;
  }

  .logo-img {
    img {
      width: 100%;
    }

    &.enabled {
      opacity: 1;
    }

    &.disabled {
      opacity: 0.8;
    }
  }
}

@media (min-width: 751px * $target-scales) {
  .logo {
    &.header-admin {
      width: 248px * $target-scales;
    }
  }
}
