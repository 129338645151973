@import 'styles/shared/_shared.scss';

.icon-button {

  &.small {
    width: 2rem;
  }
  &.medium {
    width: 4rem;
  }
  &.large {
    width: 6rem;
  }

  .image-button {
    img {
      border-radius: 50%;
    }
  }
}
