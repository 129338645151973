@import 'styles/shared/_shared.scss';

#app-layout {
  height: 100vh;
  margin: 0 auto;

  .app-layout-inner {
    width: 100%;
    position: relative;
    margin: 0 auto;

    .app-area {
      display: none;
      position: absolute;
      &.isOpen {
        display: block;
        z-index: 1000;
        width: 100%;
      }
    }

    .app-spacer-footer {
      height: 2em;
    }

    #content {
      margin: $header-height auto 0;
      padding: $px40 $px20;
      width: 100%;
      position: relative;
      background-color: $color-true-white;
    }

    > #inner-terms, #inner-privacy-policy {

      > .overlay-area {

        > .close-button-layer {
          width: 78%;
          height: 80vh;
        }

        > .overlay-inner {
          display: block;
          width: 80%;
          height: 80vh;
          overflow: hidden;
          overflow-y: scroll;
        }
      }
      .bottom-close-button {
        text-align: center;
        display: flex;
        justify-content: center;
        margin: $px20 auto;

        > button {
          font-size: $px20;
        }
      }
    }

    .privacy-policy {
      text-align: center;
      background: $color-true-white;
      padding-top: $px120;
      font-size: $px20;
    }

  }
}

@media (min-width: 751px) {
  #app-layout .app-layout-inner {
    width: $content-width * $target-scales;
    position: relative;

    #content {
      margin: $header-height-max * $target-scales auto 0;
      padding: 40px * $target-scales 20px * $target-scales;
    }

    > #inner-privacy-policy {

      > .overlay-area {

        > .close-button-layer {
          width: 78%;
          height: 80vh;
        }

        > .overlay-inner {
          display: block;
          width: 80%;
          height: 80vh;
          overflow: hidden;
          overflow-y: scroll;
        }
      }

      .bottom-close-button {
        text-align: center;
        display: flex;
        justify-content: center;
        margin: 20px * $target-scales auto;

        > button {
          font-size: 20px * $target-scales;
        }
      }
    }

    .privacy-policy {
      padding-top: 120px * $target-scales;
      font-size: 20px * $target-scales;
    }
  }
}
