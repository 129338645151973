@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');
@import 'styles/shared/_colors.scss';

html {
}

body {
  font-family:
    'Noto Sans JP',
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  font-size: $font-size-default;
  color: $color-content-text;
  background-color: $color-base-bg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

h1 {
  margin: 1.5em auto 1.0em;
  font-size: xx-large;
  font-weight: 900;
}

h2 {
  margin: 1.2em auto 0.4em;
  font-size: x-large;
  font-weight: 800;
}

a {
  color: #FF4282;
  text-decoration: underline;

  &:hover {
    opacity: 0.7;
  }
  &:visited {
    opacity: 0.7;
  }
}

button {
  font-family:
    'Noto Sans JP',
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
}
