@import 'styles/shared/_shared.scss';

.login-in-header {
  width: 8.53vw;
  margin-left: 4.8vw;
  text-align: center;

  .login-button {
    width: 100%;
    border: none;
    background-color: inherit;
    margin: 0;
    padding: 0;

    > img {
      width: 40%;
    }

    > p {
      font-size: 2.13vw;
      margin: 0.5vw 0 0;
      padding: 0;
      font-weight: bold;
    }
  }
}

@media (max-width: 470px) {
  .login-in-header {
    width: 40px;
  }
}

@media (min-width: 751px * $target-scales) {
  .login-in-header {
    width: 64px * $target-scales;
    margin-left: 36px * $target-scales;

    > .login-button {
      > img {
        width: 29.16px * $target-scales;
      }
      > p {
      font-size: 16px * $target-scales;
      margin: 4px * $target-scales 0 0 0;
      }
    }
  }
}
