@import 'styles/shared/_shared.scss';

#account-delete-completed {
  margin: $px227 auto;
  padding: $px40 $px20;

  > .title {
    font-size: $px48;
    font-weight: bold;
    text-align: center;
    margin-bottom: $px40;
  }

  > .text {
    font-size: $px24;
    line-height: $px48;
    color: #32363D;
    text-align: center;
  }

  > .button {
    margin: $px89 0;
  }

}

@media (min-width: 751px * $target-scales) {
  #account-delete-completed {
    margin: 227px * $target-scales auto;
    padding: 40px * $target-scales 20px * $target-scales;

    > .title {
      font-size: 48px * $target-scales;
      margin-bottom: 40px * $target-scales;
    }

    > .text {
      font-size: 24px * $target-scales;
      line-height: 48px * $target-scales;
    }

    > .button {
      margin: 89px * $target-scales 0;
    }
  }
}
