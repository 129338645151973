@import 'styles/shared/_shared.scss';

#register {
  width: 100%;
  margin: $px62 auto;
  text-align: left;
  padding: 0 $px20;

  .stepper-area {
    width: 100%;
    margin: 0 auto;
  }
}

@media (min-width: 751px * $target-scales) {
  #register {
    margin: 62px * $target-scales auto;
    padding: 0 20px * $target-scales;
  }
}
