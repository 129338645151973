@import 'styles/shared/_shared.scss';

.input-code-in-header {
  width: 24.5vw;
  margin-left: 4.8vw;
  text-align: center;

  .input-code-button {
    position: relative;
    width: 100%;
    height: 7.46vw;
    border: none;
    background-color: $color-white;
    margin: 0;
    padding: 0;
    border: 3px solid transparent;
    border-radius: 3.73vw;

    &::after {
      position: absolute;
      content: "";
      top: -4px;
      right: -4px;
      bottom: -4px;
      left: -4px;
    background: linear-gradient(90deg, #FF4183 0%, #FF9300 100%);
      z-index: -1;
      border-radius: 3.73vw;
    }

    > p {
      font-size: 2.66vw;
      padding: 0;
      margin: 0;
      font-weight: bold;
      color: $color-text;
    }
  }
}

@media (min-width: 751px * $target-scales) {
  .input-code-in-header {
    width: 184px * $target-scales;
    margin-left: 36px * $target-scales;

    > .input-code-button {
      height: 56px * $target-scales;
      border-radius: 28px * $target-scales;

      &::after {
        top: -5px * $target-scales;
        right: -5px * $target-scales;
        bottom: -5px * $target-scales;
        left: -5px * $target-scales;
        border-radius: 28px * $target-scales;
      }

      > p {
        font-size: 20px * $target-scales;
      }
    }
  }
}
