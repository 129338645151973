@import 'styles/shared/_shared.scss';

.menu-icon {
  width: 5.33vw;

  button {
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    background-color: inherit;
    > img {
      vertical-align:middle;
      width: 100%;
    }

    &:hover {
      opacity: 0.5;
    }
  }
}

@media (min-width: 751px) {
  .menu-icon {
    width: 40px;
  }
}
