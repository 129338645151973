@import 'styles/shared/_shared.scss';

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 100000;

  .overlay-block {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    input {
      width: 100%;
      height: 100%;
      background-color: transparent;
    }
  }

  .overlay-inner {
    width: 96%;
    height: 96%;
    position: fixed;
    top: 2%;
    left: 2%;
    background-color: $overlay-bgcolor;

    .close-button {
      position: absolute;
      top: 1em;
      right: 1em;
    }

    .fix-button {
      width: 90%;
      max-width: 10em;
      margin: 1em auto;
    }
  }
}
