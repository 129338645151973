@import 'styles/shared/_shared.scss';

$base-bg-color: $lighter-gray2;

.menu {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;

  background-color: $base-bg-color;
}
