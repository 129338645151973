@import 'styles/shared/_shared.scss';

#login {
  width: 100%;
  margin: auto;
  text-align: center;

  > .login-area {
    box-sizing: border-box;
    background-color: $color-white;
    text-align: left;
    > .input-area {
      margin: $px60 auto $px54;
    }

    > .checkbox-area {
      margin: 0 auto;
    }
  }

  .login-button {
    width: 100%;
    margin: 1em auto;
  }

  .forgot {
    margin: $px64 auto;
    font-size: $px20;
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 751px) {
  #login {
    >.login-area {
      >.input-area {
        margin: 60px auto 54px;
        > .checkbox-area {
          > .checkbox {
            font-size: 20px;
          }
        }
      }
      >.forgot {
        margin: 64px 0;
        font-size: 20px;
      }
    }
  }
}
