@import 'styles/shared/_shared.scss';



.text-field-area {
  margin-top: $px28;

  > .text-field {
    display: block;
    overflow: hidden;
    border-radius: $px20;
    box-sizing: border-box;
    width: 100%;
    height: $px106;
    padding: 0 0.3em;
    line-height: $px106;
    font-size: $px70;
    background: $color-form-field-bg;
    text-align: left;

    > input {
      width: 100%;
      box-sizing: border-box;
      border: none;
      background-color: $color-form-field-bg;
      vertical-align: top;
      font-size: $px28;
    }

    > input:focus,
    > input:focus-visible {
      outline: none;
    }
    > input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px $color-form-field-bg inset;
    }

    ::placeholder {
      color:$color-placeholder;
      font-weight: bold;
    }
  }

  &.validate {
    p {
      visibility: hidden;
      display: none;
    }
  }
  &.invalidate {
    > .text-field {
      margin-bottom: 0;
    }

    > p {
      color: $color-warning;
      font-size: $px20;
      margin-top: $px8;
    }
  }

  &.include-field-name {
    > .field-name {
      font-size: $px24;
      color: $color-form-field-name;
      font-weight: bold;

      > span.required {
        color: #FF4282;
      }

      > span.sub {
        margin-left: $px5;
      }
    }
    > .text-field {
      margin-top: $px14;
    }
  }

  &.disabled {
    > .text-field {
      background-color: $dark-gray;

      input {
        background-color: $dark-gray;
      }
    }
  }
}

@media (min-width: 751px * $target-scales) {
  .text-field-area {
    margin-top: 28px * $target-scales;

    &.invalidate p {
      font-size: 20px * $target-scales;
      margin-top: 8px * $target-scales;
    }

    &.include-field-name {
      > .field-name {
        font-size: 24px * $target-scales;

        > span.required {
          color: #FF4282;
        }

        > span.sub {
          margin-left: 5px * $target-scales;
        }
      }
      > .text-field {
        margin-top: 14px * $target-scales;
      }
    }

    > .text-field {
      border-radius: 20px * $target-scales;
      height: 106px * $target-scales;
      line-height: 106px * $target-scales;
      font-size: 70px * $target-scales;
      font-weight: bold;

      > input {
        font-size: 28px * $target-scales;
      }
    }
  }
}
