@import 'styles/shared/_shared.scss';


.register-steps {
  display: none;
  &.current {
    display: block;
  }

  > .sent-area {
    margin: 1em 0 10em;

    > .message-area {
      margin-top: $px32;
      font-size: $px24;
      line-height: 2em;

      &.small {
        font-size: $px20;
      }
    }

    > .hr {
      margin: $px56 auto;
      border-bottom: 2px solid #F4F4F4;
    }

    > h3 {
      font-size: $px28;
      font-weight: bold;
    }
  }
}

@media (min-width: 751px * $target-scales) {
  .register-steps {

    > .sent-area {
      > .message-area {
        font-size: 24px * $target-scales;
        &.small {
          font-size: 20px * $target-scales;

          > ul {
            list-style: disc;
            > li {
              margin-left: 1.4em;
            }
          }
        }
      }

      > .hr {
        margin: 56px * $target-scales auto;
      }

      > h3 {
        font-size: 28px * $target-scales;
      }
    }
  }
}
