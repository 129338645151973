/* TARGET SCALE */
// $target-scales: 0.64;
$target-scales: 1;

/* Fonts */
$font-size-default: 14pt;

/* Size */
$content-max-width: 1020px;

/* Hover */
$hover-opacity: 0.7;

/* width */
$content-width: 750px;
$content-width-middle: 480px;

/* header */
$header-height: 13.5vw;
$header-height-max: 5.7em;

/* button */
$button-hover-opacity: 0.7;
$button-disabled-opacity: 0.5;

/* サイズ */
$px-800: -106.666vw;
$px-126: -16.8vw;
$px-100: -13.333vw;
$px-96: -12.8vw;
$px-90: -12vw;
$px-86: -11.466vw;
$px-82: -10.933vw;
$px-80: -10.666vw;
$px-76: -10.133vw;
$px-71: -9.466vw;
$px-70: -9.333vw;
$px-55: -7.333vw;
$px-40: -5.33vw;
$px-30: -4vw;
$px-20: -2.66vw;
$px-15: -2vw;
$px-10: -1.33vw;
$px2: 0.266vw;
$px4: 0.53vw;
$px5: 0.666vw;
$px6: 0.08vw;
$px8: 1.06vw;
$px10: 1.33vw;
$px12: 1.6vw;
$px14: 1.866vw;
$px15: 2vw;
$px16: 2.133vw;
$px17: 2.266vw;
$px18: 2.4vw;
$px19: 2.533vw;
$px20: 2.66vw;
$px21: 2.8vw;
$px22: 2.933vw;
$px23: 3.066vw;
$px24: 3.2vw;
$px26: 3.466vw;
$px28: 3.73vw;
$px30: 4vw;
$px31: 4.133vw;
$px32: 4.266vw;
$px33: 4.4vw;
$px34: 4.533vw;
$px35: 4.666vw;
$px36: 4.8vw;
$px38: 5.066vw;
$px40: 5.333vw;
$px42: 5.6vw;
$px44: 5.866vw;
$px46: 6.133vw;
$px47: 6.266vw;
$px48: 6.4vw;
$px50: 6.666vw;
$px52: 6.933vw;
$px53: 7.066vw;
$px54: 7.2vw;
$px56: 7.466vw;
$px58: 7.733vw;
$px59: 7.866vw;
$px60: 8vw;
$px61: 8.133vw;
$px62: 8.266vw;
$px64: 8.533vw;
$px66: 8.8vw;
$px68: 9.066vw;
$px70: 9.33vw;
$px72: 9.6vw;
$px73: 9.733vw;
$px74: 9.866vw;
$px76: 10.133vw;
$px80: 10.666vw;
$px82: 10.933vw;
$px84: 11.2vw;
$px86: 11.46vw;
$px89: 11.86vw;
$px92: 12.26vw;
$px94: 23.533vw;
$px96: 12.8vw;
$px98: 13.066vw;
$px99: 13.2vw;
$px100: 13.333vw;
$px102: 13.6vw;
$px104: 13.866vw;
$px106: 14.13vw;
$px108: 14.4vw;
$px112: 14.933vw;
$px114: 15.2vw;
$px116: 15.466vw;
$px118: 15.733vw;
$px120: 16vw;
$px126: 16.8vw;
$px128: 17.066vw;
$px130: 17.333vw;
$px132: 17.6vw;
$px134: 17.866vw;
$px136: 18.133vw;
$px140: 18.666vw;
$px142: 18.933vw;
$px144: 19.2vw;
$px152: 20.266vw;
$px158: 21.066vw;
$px160: 21.333vw;
$px164: 21.866vw;
$px170: 22.666vw;
$px174: 23.2vw;
$px178: 23.733vw;
$px180: 24vw;
$px184: 24.533vw;
$px185: 24.666vw;
$px188: 25.066vw;
$px192: 25.6vw;
$px194: 25.866vw;
$px200: 26.666vw;
$px207: 27.6vw;
$px210: 28vw;
$px216: 28.8vw;
$px222: 29.6vw;
$px227: 30.266vw;
$px240: 32vw;
$px248: 33.0666vw;
$px250: 33.333vw;
$px259: 34.533vw;
$px260: 32.666vw;
$px274: 36.533vw;
$px292: 38.933vw;
$px308: 41.066vw;
$px316: 42.133vw;
$px320: 42.666vw;
$px324: 43.2vw;
$px328: 43.733vw;
$px340: 45.333vw;
$px360: 48vw;
$px370: 49.333vw;
$px388: 51.733vw;
$px394: 52.533vw;
$px400: 53.333vw;
$px412: 54.9333vw;
$px425: 56.666vw;
$px430: 57.333vw;
$px438: 58.4vw;
$px440: 58.666vw;
$px480: 64vw;
$px492: 65.6vw;
$px500: 66.666vw;
$px504: 67.2vw;
$px508: 67.733vw;
$px512: 68.266vw;
$px520: 69.333vw;
$px548: 73.06vw;
$px560: 74.666vw;
$px568: 75.733vw;
$px592: 78.933vw;
$px600: 80vw;
$px605: 80.666vw;
$px614: 81.866vw;
$px616: 82.133vw;
$px630: 84vw;
$px640: 85.33vw;
$px656: 87.46vw;
$px660: 88vw;
$px670: 89.333vw;
$px700: 93.333vw;
$px710: 94.666vw;
$px750: 100vw;
$px820: 109.333vw;
$px850: 113.333vw;
$px1478: 197.066vw;
