@import 'styles/shared/_shared.scss';

#account-delete {
  margin: 0 auto;
  padding: $px40 $px20;

  > .page-header.page-header-main {
    margin-top: $px35;
  }

  > .text {
    font-size: $px24;
    line-height: $px48;
    color: #32363D;
  }

  > .button {
    margin: $px89 0;
  }

}

@media (min-width: 751px * $target-scales) {
  #account-delete {
    padding: 40px * $target-scales 20px * $target-scales;

    > .page-header.page-header-main {
      margin-top: 35px * $target-scales;
    }

    > .text {
      font-size: 24px * $target-scales;
      line-height: 48px * $target-scales;
    }

    > .button {
      margin: 89px * $target-scales 0;
    }
  }
}
