@import 'styles/shared/_shared.scss';

.edit-property-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #F4F4F4;

  > .text {
    > .text-confirm {
      border-bottom: none;
    }
  }

  > .button {
    > .text-button {
      > button {
        &.black {
          justify-content: right;
          width: $px158;
          padding: $px20;
        }
        > img.left-icon {
          left: $px20;
        }
      }
    }
  }
}

@media (min-width: 751px * $target-scales) {
  .edit-property-item {
    > .button {
      > .text-button {
        > button {
          &.black {
            width: 158px * $target-scales;
            padding: 20px * $target-scales;
          }
          > img.left-icon {
            left: 20px * $target-scales;
          }
        }
      }
    }
  }
}
