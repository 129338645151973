@import 'styles/shared/_shared.scss';

.overlay-area {
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  padding: 0;
  padding: $px114 0 $px192;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000000;
  display: none;
  justify-content: center;
  align-items: center;

  &.open {
    display: flex;
  }

  > .overlay-layer {
    width: 100%;
    height: 100%;
    background-color: #32363D;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
  }
  > .close-button-layer {
    position: relative;
    width: $px700;
    height: 80vh;

    > .close-button {
      width: $px72;
      height: $px72;
      position: absolute;
      top: $px30;
      right: $px20;
      z-index: 100;

      > img {
        width: 100%;
      }

      &:hover {
        opacity: $button-hover-opacity;
      }
    }
  }

  > .overlay-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-true-white;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: $px40;
    // height: 80vh;
    // overflow: hidden;
    // overflow-y: scroll; // TODO: ここでやらないかも

    &::-webkit-scrollbar{
      display: none;
    }

    > .page-header-main {
      margin-left: $px40;
    }
  }


  &.webview {

    > .close-button-layer {
      position: relative;
      width: 78%;
      height: 80vh;

      > .close-button {
        width: $px72;
        height: $px72;
        position: absolute;
        top: $px30;
        right: $px20;
        z-index: 100;

        > img {
          width: 100%;
        }

        &:hover {
          opacity: $button-hover-opacity;
        }
      }
    }
    > .overlay-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-true-white;
      width: 80%;
      margin: 0 auto;
      position: absolute;
      // left: 50%;
      // transform: translateX(-50%);
      border-radius: $px40;
      height: 80vh;
      overflow: hidden;
      overflow-y: scroll; // TODO: ここでやらないかも

      &::-webkit-scrollbar{
        display: none;
      }

      > .page-header-main {
        margin-left: $px40;
      }
    }
  }

  &.minimum {
    > .overlay-inner {
      background-color: inherit;
    }
  }
}

@media (min-width: 751px * $target-scales) {
  .overlay-area {
    padding: 114px * $target-scales 0 192px * $target-scales;

    > .close-button-layer {
      width: 700px * $target-scales;
      height: 80vh;
      > .close-button {
        width: 72px * $target-scales;
        height: 72px * $target-scales;
        // top: 100px;
        top: -80px * $target-scales;
        right: 13px * $target-scales;
        // top: 30px * $target-scales;
        // right: 20px * $target-scales;
      }
    }

    > .overlay-inner {
      width: 700px * $target-scales;
      border-radius: 40px * $target-scales;

      > .page-header-main {
        margin-left: 40px * $target-scales;
      }
    }

    &.webview {
      > .close-button-layer {
        width: 78%;
        height: 80vh;
        > .close-button {
          width: 72px * $target-scales;
          height: 72px * $target-scales;
          top: 30px * $target-scales;
          right: 20px * $target-scales;
        }
      }

      > .overlay-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-true-white;
        width: 80%;
        margin: 0 auto;
        position: absolute;
        border-radius: 40px * $target-scales;
        height: 80vh;
        overflow: hidden;
        overflow-y: scroll;

        &::-webkit-scrollbar{
          display: none;
        }

        > .page-header-main {
          margin-left: 40px * $target-scales;
        }
      }
    }
  }
}
