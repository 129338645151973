@import 'styles/shared/_shared.scss';
@import 'CommonPage.scss';

#privacy-policy {
    text-align: left;

    > .page-header-main {
        margin-left: $px20;
    }
}

@media (min-width: 751px * $target-scales) {
    > .page-header-main {
        margin-left: 20px * $target-scales;
    }
}
