@import 'styles/shared/_shared.scss';

.text-button {
  width: 100%;

  button {
    position:relative;
    display: flex;
    align-items: center;
    background: $gradation-background;
    width: 100%;
    margin: 0;
    padding: $px36;
    color: $color-true-white;
    font-size: $px28;
    line-height: $px28;
    font-weight: bold;
    height: $px100;
    border: none;
    border-radius: $px16;
    align-content: center;
    justify-content: center;
    cursor: pointer;
    // TODO: 念のため少し残しておく
    // padding: 0.7em 2.1em 0.65em 2.1em;
    // font-size: 0.95rem;
    // color: $color-main-reverse;
    // background-color: $color-main-emphasis;
    // border-radius: 0.3em;
    // box-sizing: border-box;
    // line-height: 1.5em;
    // img {
    //   margin: 0 13px 0 -0.9em;
    // }

    &:hover {
      opacity: $button-hover-opacity;
    }

    &.disabled {
      opacity: $button-disabled-opacity;
    }

    > img.right-icon {
      position:absolute;
      right: $px50;
      width: $px20;
    }

    > img.left-icon {
      position:absolute;
      left: $px32;
      width: $px20;
    }

    &.back {
      background: #EAEAEA;
      color: #676C74;
      font-weight: bold;
    }

    &.border {
      color: #32363D;
      background: $color-true-white;
      border: 2px solid #FF6845;
    }

    &.confirm {
      padding: 0.9em 0;
      color: $color-content-text-reverse;
      background-color: $color-main-emphasis;
    }

    &.warning {
      color: $color-warning;
      background: $color-true-white;
      border: 2px solid $color-warning;
    }

    &.small {
      font-size: $px10;
      font-weight: normal;
      height: 3em;
      line-height: 3em;
      padding: 1em 2em;

      img {
        margin-right: 5px;
        width: 1em;
      }
    }

    &.minimal {
      font-size: $px10;
      background: inherit;
      height: 2em;
      line-height: 2em;
      color: inherit;
      border: none;
      margin: 0;
      padding: 0;
      font-weight: normal;
    }

    &.minimal-underline {
      font-size: $px10;
      background: inherit;
      height: 2em;
      line-height: 2em;
      color: inherit;
      border: none;
      margin: 0;
      padding: 0;
      font-weight: normal;
      border-bottom: 1px solid #676C74;
      width: fit-content;
      border-radius: 0;
    }

    &.link {
      display: inline;
      font-size: inherit;
      background: inherit;
      height: inherit;
      line-height: inherit;
      color: #FF4282;
      border: none;
      margin: 0 0 2px;
      padding: 0;
      font-weight: normal;
      border-bottom: 1px solid #FF4282;
      width: fit-content;
      border-radius: 0;
    }

    &.rounded {
      background-color: $darker-gray2;
      color: $true-white;
      font-weight: bbold;
      border-radius: $px56;
    }

    &.black {
      background: #32363D;
      width: $px180;
      height: $px64;
      font-size: $px20;
      padding: $px22 $px30;
      justify-content: left;

      > img.right-icon {
        position:absolute;
        right: $px24;
        width: $px15;
      }
    }

    &.white {
      background: $color-true-white;
      color: #FF5562;
      font-weight: bbold;
      border-radius: $px56;
    }

    &.inline-close {
      background: #eaeaea;
      color: #676C74;
      width: $px180;
      height: $px64;
      font-size: $px20;
      padding: $px22 $px50;
      justify-content: left;

      > img.right-icon {
        position:absolute;
        right: $px24;
        width: $px15;
      }
    }
  }
}

@media (min-width: 751px * $target-scales) {
  .text-button {
    > button {
      padding: 36px * $target-scales;
      font-size: 28px * $target-scales;
      line-height: 28px * $target-scales;
      height: 100px * $target-scales;
      border-radius: 16px * $target-scales;

      > img.right-icon {
        width: 20px * $target-scales;
        right: 50px * $target-scales;
      }

      > img.left-icon {
        left: 32px * $target-scales;
        width: 20px * $target-scales;
      }

      &.small {
        font-size: 10px * $target-scales;
      }

      &.rounded {
        border-radius: 56px * $target-scales;
      }

      &.black {
        height: 64px * $target-scales;
        width: 180px * $target-scales;
        font-size: 20px * $target-scales;
        padding: 22px * $target-scales 30px * $target-scales;

        > img.right-icon {
          position:absolute;
          right: 24px * $target-scales;
          width: 15px * $target-scales;
        }
      }

      &.inline-close {
        background: #eaeaea;
        color: #676C74;
        width: 180px * $target-scales;
        height: 64px * $target-scales;
        font-size: 20px * $target-scales;
        padding: 22px * $target-scales 50px * $target-scales;
        justify-content: left;

        > img.right-icon {
          position:absolute;
          right: 24px * $target-scales;
          width: 15px * $target-scales;
        }
      }
    }
  }
}
