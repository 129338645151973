@import 'styles/shared/_shared.scss';


.select-birthday {
  width: 100%;
  margin-top: $px28;

  > .field-name {
    font-size: $px24;
    color: $color-form-field-name;
    font-weight: bold;
  }

  > .birthday-set {
    margin-top: $px14;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > select {
      width: 32%;
      padding: 0 $px40;
      height: $px106;
      color: #A0A4AA;
      font-size: $px28;
      background-color: $color-form-field-bg;
      background-image: url(../../images/icon_down_arrow.svg);
      background-size: $px21;
      background-repeat: no-repeat;
      background-position: right 1em center;
      border-radius: $px20;
      -webkit-appearance: none;
      appearance: none;
    }

    select::-ms-expand {
      display: none;
    }
  }

  &.validate {
    > p {
      visibility: hidden;
      display: none;
    }
  }
  &.invalidate {
    > .text-field {
      margin-bottom: 0;
    }

    > p {
      color: $color-warning;
      font-size: $px20;
      margin-top: $px8;
    }
  }
}

@media (min-width: 751px * $target-scales) {
  .select-birthday {
    margin-top: 28px * $target-scales;

    > .field-name {
      font-size: 24px * $target-scales;
    }

    > .birthday-set {
      margin-top: 14px * $target-scales;
      > select {
        padding: 0 40px * $target-scales;
        height: 106px * $target-scales;
        font-size: 28px * $target-scales;
        background-size: 21px * $target-scales;
        border-radius: 20px * $target-scales;
      }
    }

    &.invalidate {
      > p {
        font-size: 20px * $target-scales;
        margin-top: 8px * $target-scales;
      }
    }
    }
}
