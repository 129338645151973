@import 'styles/shared/_shared.scss';

.common-page {

  #common-document-format {
    margin: $px60 auto;
    padding: 0 $px40;

    .default {
      font-size: $px24;
      line-height: $px48;
      margin-top: $px32;
    }
    .paragraph-title {
      font-size: $px28;
      line-height: $px32;
      font-weight: bold;
      margin-top: $px72;
    }
  }
}

@media (min-width: 751px * $target-scales) {
  .common-page {
    #common-document-format {
      padding: 0 40px * $target-scales;

      .default {
        font-size: 24px * $target-scales;
        line-height: 48px * $target-scales;
        margin-top: 32px * $target-scales;
      }

      .paragraph-title {
        font-size: 28px * $target-scales;
        line-height: 32px * $target-scales;
        margin-top: 72px * $target-scales;
      }
    }
  }
}
