@import 'styles/shared/_shared.scss';

#account {
  width: 100%;
  height: 100%;

  .account-info {
    margin: 3em auto;
    width: 70%;
    min-width: 300px;
    display: flex;
    flex-wrap: wrap;

    .account-icon {
      position: relative;
      margin: 0 3em 2em 0;
    }

    .account-text {
      flex-grow: 1;
    }
  }

  > .account-delete-link {
    margin-top: $px100;
    text-align: center;
    font-size: $px24;
    color: #676C74;

    > a {
      color: #676C74;
    }
  }
}

@media (min-width: 751px * $target-scales) {
  #account {
    > .account-delete-link {
      margin-top: 100px * $target-scales;
      font-size: 24px * $target-scales;
    }
  }
}
